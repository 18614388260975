
import { Options } from 'vue-class-component'
import Common from '@/components/barcodeSearch/customField/Common.vue'
import { Mixins, PropSync } from 'vue-property-decorator'
import { getBarcodeSearchAutoComplete } from '@/apis/barcodeAPI'
import { BARCODE_AUTO_COMPLETE_SEARCH_KEYS } from '@/utils/constants'
import { IBarcodeSearchAutoCompleteRequest } from 'smartbarcode-web-core/src/utils/types'
import ExactValueButton from '@/components/common/ExactValueButton.vue'
interface IAutoCompleteResults {
  key: string
  value: string
}
@Options({
  components: {
    ExactValueButton,
  },
  name: 'Input',
})
export default class Input extends Mixins(Common) {
  @PropSync('modelValue', { type: String }) _value!: string
  get isAutoCompleteSearch() {
    const searchKey = this.currentSearchKey
    return BARCODE_AUTO_COMPLETE_SEARCH_KEYS.includes(searchKey)
  }

  get currentSearchKey() {
    const searchKey = `${this._searchItem?.key}.${this._searchItem?.subKey}.${this._searchItem?.componentType}`
    if (this._searchItem) {
      this._searchItem.isExactValue = false
    }
    return searchKey
  }

  get constructAutcoCompleteQuery() {
    const { projectCode, version, projectId } = this.searchData

    const autoCompleteSearch: IBarcodeSearchAutoCompleteRequest = {
      condition: {
        projectCode,
        version,
        projectId,
        autoComplete: {
          key: this.currentSearchKey,
          value: this._value || '',
        },
      },
    }
    return autoCompleteSearch
  }

  get searchData() {
    return this.$store.state?.barcode?.dataSearch
  }

  async querySearch(queryString: string, cb: Function) {
    const emptyValue: string[] = []
    try {
      if (queryString?.length > 1) {
        const searchCondition = this.constructAutcoCompleteQuery
        const searchResults: IAutoCompleteResults[] = await getBarcodeSearchAutoComplete(searchCondition)
        const results = searchResults
          .map((val) => {
            return { label: val?.key, value: val?.value }
          })
          .filter(
            (value, index, self) => index === self.findIndex((t) => t.label === value.label && t.value === value.value)
          )
        cb(results)
      } else {
        cb(emptyValue)
      }
    } catch (error) {
      cb(emptyValue)
    }
  }
}
