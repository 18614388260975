
import SearchItemAddress from '@/components/barcodeSearch/SearchItemAddress.vue'
import SearchItemBarcodeType from '@/components/barcodeSearch/SearchItemBarcodeType.vue'
import SearchItemBoolean from '@/components/barcodeSearch/SearchItemBoolean.vue'
import SearchItemCustomField from '@/components/barcodeSearch/SearchItemCustomField.vue'
import SearchItemDate from '@/components/barcodeSearch/SearchItemDate.vue'
import SearchItemDimension from '@/components/barcodeSearch/SearchItemDimension.vue'
import SearchItemTextbox from '@/components/barcodeSearch/SearchItemTextbox.vue'
import SearchItemTrackingData from '@/components/barcodeSearch/SearchItemTrackingData.vue'
import SearchItemNumber from '@/components/barcodeSearch/SearchItemNumber.vue'
import SearchItemTrackpoint from '@/components/barcodeSearch/SearchItemTrackpoint.vue'
import SearchItemUser from '@/components/barcodeSearch/SearchItemUser.vue'
import IconTrash from '@/components/svg/IconTrash.vue'
import { BARCODE_SEARCH_KEYS, BARCODE_SEARCH_VALUE_TYPES } from '@/utils/constants'
import {
  IBarcodeSearchFormItem,
  ITrackpoint,
  TArrProjectCustomField,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'

@Options({
  components: {
    SearchItemDate,
    SearchItemTextbox,
    SearchItemUser,
    SearchItemTrackpoint,
    SearchItemAddress,
    SearchItemDimension,
    SearchItemCustomField,
    SearchItemBoolean,
    SearchItemBarcodeType,
    SearchItemTrackingData,
    IconTrash,
  },
  emits: ['update:searchItem', 'remove:searchItem', 'add:searchItem', 'change:barcodeType'],
  name: 'SearchItem',
})
export default class SearchItem extends Vue {
  @InjectReactive() projectVersion!: number
  @PropSync('searchItem', { type: Object }) _searchItem!: IBarcodeSearchFormItem
  @Prop({ type: Number }) readonly index!: number
  @Prop({ type: Boolean }) readonly isRemoveButton!: false
  @Prop({ type: Boolean }) readonly isAddButton!: false
  @Prop({ type: Array }) readonly projectCustomFields!: TArrProjectCustomField[]
  @Prop({ type: Object }) readonly barcodeTypes?: {}
  @Prop({ type: Object }) readonly trackPoints?: {}

  get customKeySelectDatas() {
    const result = { ...BARCODE_SEARCH_KEYS }
    if (this.projectVersion === 0) {
      delete result.currentTrackpoint
      delete result.barcodeType
    }
    if (!this.isEnableSearchTrackingData) {
      delete result.trackingData
    }

    return result
  }

  get isEnableSearchTrackingData() {
    return this.$store.getters.isEnableSearchTrackingData
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return (
      this.$store.state.barcode?.projectList?.trackPoints ??
      this.trackPoints ??
      this.$store.state.barcode?.projectDetail?.trackPoints ??
      {}
    )
  }

  get bcTypes() {
    return (
      this.$store.state.barcode.projectList.barcodeTypes ||
      this.$store.state.barcode.projectDetail.barcodeTypes ||
      this.barcodeTypes ||
      {}
    )
  }

  onKeyChanged() {
    const searchItem = {
      key: this._searchItem.key,
      valueType: '',
      bcTypeKey: '',
      subKey: '',
      componentType: '',
      maxValue: '',
      minValue: '',
      value: '',
      valueList: [],
      isExactValue: false,
    } as {
      key: string
      valueType: string
      bcTypeKey: string
      subKey: string
      componentType: string
      maxValue: string
      minValue: string
      value: string | number
      valueList: string[]
      isExactValue: boolean
    }

    switch (searchItem.key) {
      case BARCODE_SEARCH_KEYS.createdDate:
      case BARCODE_SEARCH_KEYS.updatedDate:
      case BARCODE_SEARCH_KEYS.lastTrackingDateTime:
      case BARCODE_SEARCH_KEYS.activatedDateTime:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.date
        break
      case BARCODE_SEARCH_KEYS.createdUser:
      case BARCODE_SEARCH_KEYS.updatedUser:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.string
        break
      case BARCODE_SEARCH_KEYS.origin:
      case BARCODE_SEARCH_KEYS.destination:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.string
        break
      case BARCODE_SEARCH_KEYS.dimension:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.double
        break
      case BARCODE_SEARCH_KEYS.currentTrackpoint:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.string
        break
      case BARCODE_SEARCH_KEYS.isActivated:
      case BARCODE_SEARCH_KEYS.isDeactivated:
      case BARCODE_SEARCH_KEYS.isActive:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.boolean
        break
      case BARCODE_SEARCH_KEYS.barcodeType:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.string
        break
      case BARCODE_SEARCH_KEYS.customField:
        searchItem.valueType = searchItem.valueType !== '' ? searchItem.valueType : BARCODE_SEARCH_VALUE_TYPES.string
        break
      case BARCODE_SEARCH_KEYS.id:
        searchItem.isExactValue = true
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.string
        break
      case BARCODE_SEARCH_KEYS.recycleCount:
        searchItem.value = 0
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.integer
        break
      case BARCODE_SEARCH_KEYS.trackingNumber:
      default:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.string
        break
    }
    this._searchItem = searchItem as IBarcodeSearchFormItem
  }

  getSearchItemComponent(searchKey: string) {
    switch (searchKey) {
      case BARCODE_SEARCH_KEYS.createdDate:
      case BARCODE_SEARCH_KEYS.updatedDate:
      case BARCODE_SEARCH_KEYS.activatedDateTime:
      case BARCODE_SEARCH_KEYS.lastTrackingDateTime:
        return { component: SearchItemDate }

      case BARCODE_SEARCH_KEYS.createdUser:
      case BARCODE_SEARCH_KEYS.updatedUser:
        return { component: SearchItemUser }

      case BARCODE_SEARCH_KEYS.origin:
      case BARCODE_SEARCH_KEYS.destination:
        return { component: SearchItemAddress }

      case BARCODE_SEARCH_KEYS.dimension:
        return { component: SearchItemDimension }

      case BARCODE_SEARCH_KEYS.currentTrackpoint:
        return { component: SearchItemTrackpoint, props: { trackPoints: this.trackpoints } }

      case BARCODE_SEARCH_KEYS.isActivated:
      case BARCODE_SEARCH_KEYS.isDeactivated:
      case BARCODE_SEARCH_KEYS.isActive:
        return { component: SearchItemBoolean }
      case BARCODE_SEARCH_KEYS.recycleCount:
        return { component: SearchItemNumber }

      case BARCODE_SEARCH_KEYS.id:
      case BARCODE_SEARCH_KEYS.trackingNumber:
      default:
        return { component: SearchItemTextbox }
    }
  }

  changeBarcodeType() {
    this.$emit('change:barcodeType')
  }
}
