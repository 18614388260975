<template>
  <div>
    <el-select
      @change="onFromTrackPointKeychanged()"
      size="medium"
      v-model="_searchItem.fromTrackPointKey"
      :placeholder="$t('barcode_type.select')"
    >
      <el-option
        v-for="tpData in trackingPointDatasArr"
        :key="`${tpData.value.name} (${tpData.key})`"
        :value="tpData.key"
        :label="`${tpData?.value?.name} (${tpData.key})`"
      >
        {{ tpData.value.name }} {{ `(${tpData.key})` }}
      </el-option>
    </el-select>
    <el-select size="medium" v-model="_searchItem.toTrackPointKey" :placeholder="$t('barcode_type.select')">
      <el-option
        v-for="tpData in loadTP(_searchItem.fromTrackPointKey)"
        :key="`${tpData.value.name} (${tpData.key})`"
        :value="tpData.key"
        :label="`${tpData?.value?.name} (${tpData.key})`"
      >
        {{ tpData.value.name }} {{ `(${tpData.key})` }}
      </el-option>
    </el-select>
  </div>
  <div class="flex">
    <el-select
      v-model="trackingDataKey"
      :key="trackingDataKey"
      size="medium"
      :placeholder="$t('selectOne')"
      class="w-full"
      @change="onTrackingDataKeyChanged"
    >
      <el-option v-for="(value, key) in trackingDataKeyOptions" :key="key" :label="$t('barcode.' + key)" :value="value">
        {{ $t('barcode.' + key) }}
      </el-option>
    </el-select>
  </div>
  <div v-if="trackingDataKey">
    <div v-if="trackingDataKey === 'customFields'" class="flex">
      <SelectAutocompleteLabel
        @change:value="customFieldSelectedChanged"
        :isMedium="true"
        :isDropdownlist="projectVersion !== 0"
        v-model="customFieldKeySelected"
        :options="trackingDataOptions"
        name="customFieldKey"
        @check:IsValueInOptions="updateValueInOptions"
      />

      <div v-if="isShowComponentType" class="">
        <el-select
          :key="customFieldKeySelected"
          v-model="_searchItem.componentType"
          :placeholder="$t('fieldType')"
          size="medium"
          class="w-full"
          @change="changeComponentType"
        >
          <el-option
            v-for="value in customFieldSelectDatas"
            :key="value"
            :label="$t('customFields.' + value)"
            :value="value"
          >
            {{ $t('customFields.' + value) }}
          </el-option>
        </el-select>
      </div>
      <div v-if="isShowComponentValue" class=" search-value-field">
        <MultiSelect
          v-if="customComponentFieldType === customFieldMultiKey"
          :key="`if_${customFieldKeySelected}${_searchItem.componentType}`"
          v-model="_searchItem.valueList"
          :selectOptions="selectOptions"
        />
        <div v-else>
          <component
            :key="`else_${customFieldKeySelected}${_searchItem.componentType}`"
            :is="getCustomFieldComponent()"
            v-model="_searchItem.value"
            :selectOptions="selectOptions"
            :searchKey="_searchItem"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <component
        :is="getSearchItemComponent(trackingDataKey).component"
        v-bind="getSearchItemComponent(trackingDataKey)"
        v-model:searchItem="_searchItem"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Date from '@/components/barcodeSearch/customField/Date.vue'
import Input from '@/components/barcodeSearch/customField/Input.vue'
import MultiSelect from '@/components/barcodeSearch/customField/MultiSelect.vue'
import NumberComponent from '@/components/barcodeSearch/customField/Number.vue'
import SingleSelect from '@/components/barcodeSearch/customField/SingleSelect.vue'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import SelectAutocompleteLabel from '@/components/common/SelectAutocompleteLabel.vue'
import SelectMultiAutocompleteLabel from '@/components/common/SelectMultiAutocompleteLabel.vue'
import {
  BARCODE_SEARCH_KEYS,
  BARCODE_SEARCH_VALUE_TYPES,
  BARCODE_TRACKING_SEARCH_KEYS,
  CUSTOM_FIELD_KEY,
} from '@/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IFieldOption,
  IProject,
  ITrackPointKeyVal,
  TArrProjectCustomField,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { InjectReactive, Mixins } from 'vue-property-decorator'
import SearchItemBoolean from '@/components/barcodeSearch/SearchItemBoolean.vue'
import SearchItemCustomField from '@/components/barcodeSearch/SearchItemCustomField.vue'
import SearchItemDate from '@/components/barcodeSearch/SearchItemDate.vue'
import SearchItemTextbox from '@/components/barcodeSearch/SearchItemTextbox.vue'
import SearchItemUser from '@/components/barcodeSearch/SearchItemUser.vue'

export interface ICustomComponent {
  number: IComponentSetting
  text: IComponentSetting
  date: IComponentSetting
  singleSelect: IComponentSetting
  multiSelect: IComponentSetting
  email: IComponentSetting
  phoneNumber: IComponentSetting
}

export interface IComponentSetting {
  default: string | number | string[]
  type: string
}

@Options({
  components: {
    InputOnlyNumber,
    SelectAutocompleteLabel,
    SelectMultiAutocompleteLabel,
    Input,
    Number,
    MultiSelect,
    SingleSelect,
    Date,
    NumberComponent,
    SearchItemDate,
    SearchItemTextbox,
    SearchItemUser,
    SearchItemCustomField,
    SearchItemBoolean,
  },
  name: 'SearchItemTrackingData',
})
export default class SearchItemTrackingData extends Mixins(SearchItemField) {
  @InjectReactive() projectVersion!: number
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]

  customFieldKeySelected = ''
  trackingDataKey = ''
  isValueInOptions = false
  isChangeFromCreated = false

  getSearchItemComponent(searchKey: string) {
    switch (searchKey) {
      case BARCODE_TRACKING_SEARCH_KEYS.trackedDateTime:
        return { component: SearchItemDate }

      case BARCODE_TRACKING_SEARCH_KEYS.trackedUser:
        return { component: SearchItemUser }

      case BARCODE_TRACKING_SEARCH_KEYS.isSkip:
        return { component: SearchItemBoolean }

      default:
        return { component: SearchItemTextbox }
    }
  }

  getSearchCustomFields(): TArrProjectCustomField[] {
    const currentTPCustomFields = this.getCurrentProject?.trackPoints?.[this._searchItem?.fromTrackPointKey]
      ?.trackPointForms?.[this._searchItem?.toTrackPointKey]?.customFields
    if (!currentTPCustomFields) return []

    const customFieldSearchKeys = [
      CUSTOM_FIELD_KEY.text,
      CUSTOM_FIELD_KEY.number,
      CUSTOM_FIELD_KEY.singleSelect,
      CUSTOM_FIELD_KEY.multiSelect,
      CUSTOM_FIELD_KEY.date,
      CUSTOM_FIELD_KEY.email,
      CUSTOM_FIELD_KEY.phoneNumber,
    ]
    const result = [] as TArrProjectCustomField[]
    for (const key in currentTPCustomFields) {
      if (customFieldSearchKeys.includes(currentTPCustomFields[key]?.fieldType)) {
        result.push({ ...currentTPCustomFields[key], customFieldKey: key } as TArrProjectCustomField)
      }
    }
    return result
  }

  get isShowComponentValue() {
    return (
      (this.projectVersion === 0 && this._searchItem?.componentType && this._searchItem?.componentType !== '') ||
      (this.isValueInOptions
        ? this.customFieldKeySelected !== ''
        : this.customFieldKeySelected !== '' &&
          this._searchItem?.componentType &&
          this._searchItem?.componentType !== '')
    )
  }

  get getCurrentProject(): IProject {
    return this.$store.state.barcode?.projectList
  }

  get trackingDataOptions() {
    const customFields = (this.getSearchCustomFields() || []).reduce(
      (result: IFieldOption[], item: TArrProjectCustomField) => [
        ...result,
        ...[{ label: item.label, value: item.customFieldKey }],
      ],
      [] as IFieldOption[]
    )

    return customFields
  }

  get selectOptions() {
    const result = [] as IFieldOption[]
    for (const key in this.customFieldSettingSelected?.selections) {
      result.push({
        value: key,
        label: this.customFieldSettingSelected.selections?.[key]?.label,
      })
    }
    return result
  }

  get searchKey() {
    return this._searchItem.key
  }

  get trackingDataKeyOptions() {
    const result = { ...BARCODE_TRACKING_SEARCH_KEYS }
    if (this.projectVersion === 0) {
      delete result.customField
    }
    return result
  }

  get customFieldSelectDatas() {
    return [
      CUSTOM_FIELD_KEY.text,
      CUSTOM_FIELD_KEY.number,
      CUSTOM_FIELD_KEY.singleSelect,
      CUSTOM_FIELD_KEY.multiSelect,
      CUSTOM_FIELD_KEY.date,
      CUSTOM_FIELD_KEY.email,
      CUSTOM_FIELD_KEY.phoneNumber,
    ]
  }

  get currentValue() {
    return this.customFieldTypeDefaultValueMap[this.customComponentFieldType].default
  }

  get currentValueType() {
    return this.customFieldTypeDefaultValueMap[this.customComponentFieldType].type
  }

  get customFieldTypeDefaultValueMap(): ICustomComponent {
    return {
      number: { default: 0, type: 'integer' },
      text: { default: '', type: 'string' },
      date: { default: [], type: 'date' },
      singleSelect: { default: '', type: 'string' },
      multiSelect: { default: [] as string[], type: 'string' },
      email: { default: '', type: 'string' },
      phoneNumber: { default: '', type: 'string' },
    }
  }

  get customComponentFieldType() {
    const type = this._searchItem.componentType || 'text'
    return type as 'number' | 'text' | 'singleSelect' | 'multiSelect' | 'date' | 'email' | 'phoneNumber'
  }

  get isShowComponentType() {
    return this.projectVersion === 0 || (this.customFieldKeySelected !== '' && !this.isValueInOptions)
  }

  get customFieldMultiKey() {
    return CUSTOM_FIELD_KEY.multiSelect
  }

  getValueTypeByKey(key: keyof ICustomComponent) {
    return this.customFieldTypeDefaultValueMap[key].type
  }

  getCustomFieldComponent() {
    let component = ''
    switch (this.customComponentFieldType) {
      case CUSTOM_FIELD_KEY.text:
      case CUSTOM_FIELD_KEY.email:
      case CUSTOM_FIELD_KEY.phoneNumber:
        component = 'Input'
        break
      case CUSTOM_FIELD_KEY.number:
        component = 'NumberComponent'
        this._searchItem.value = Number(this._searchItem.value)
        break
      case CUSTOM_FIELD_KEY.singleSelect:
        component = 'SingleSelect'
        break
      case CUSTOM_FIELD_KEY.date:
        component = 'Date'
        break
    }
    return component
  }

  created() {
    Object.keys(BARCODE_TRACKING_SEARCH_KEYS).forEach((key) => {
      if (this._searchItem.subKey.toString().includes(key)) {
        this.trackingDataKey = BARCODE_TRACKING_SEARCH_KEYS[key]
        const spitedSubKey = this._searchItem.subKey.split('customFields.')
        this.customFieldKeySelected = spitedSubKey.length > 0 ? spitedSubKey[1] : spitedSubKey[0]
      }
    })
    if (this._searchItem.subKey) {
      this.isChangeFromCreated = true
    }
  }

  onFromTrackPointKeychanged() {
    this._searchItem.toTrackPointKey = ''
    this.customFieldKeySelected = ''
    this._searchItem.componentType = ''
  }

  updateValueInOptions(value: boolean) {
    this.isValueInOptions = value
  }

  get customFieldSettingSelected() {
    const customFieldKey = this.customFieldKeySelected
    return (
      (this.getSearchCustomFields() ?? []).find(
        (item: TArrProjectCustomField) => item.customFieldKey === customFieldKey
      ) ?? ({} as TArrProjectCustomField)
    )
  }

  onTrackingDataKeyChanged() {
    this._searchItem.subKey = this.trackingDataKey

    this._searchItem.value = ''
    this._searchItem.valueList = []
    this._searchItem.minValue = ''
    this._searchItem.maxValue = ''
    this.customFieldKeySelected = ''

    switch (this.trackingDataKey) {
      case BARCODE_TRACKING_SEARCH_KEYS.trackedDateTime:
        this._searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.date
        break
      case BARCODE_TRACKING_SEARCH_KEYS.trackedUser:
        this._searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.string
        break
      case BARCODE_TRACKING_SEARCH_KEYS.isSkip:
        this._searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.boolean
        break
      default:
        break
    }
  }

  customFieldSelectedChanged() {
    const customFieldKey = this.customFieldKeySelected

    this.isValueInOptions = !isEmpty(this.customFieldSettingSelected)

    if (this.isChangeFromCreated) {
      this.isChangeFromCreated = false
      if (this._searchItem.componentType === '') {
        this._searchItem.componentType = this.customFieldSettingSelected.fieldType
      }
    } else {
      this._searchItem.subKey = `${this.trackingDataKey}.${customFieldKey}`

      if (this.isValueInOptions) {
        this._searchItem.componentType = this.customFieldSettingSelected.fieldType
      }
      this._searchItem.valueType = this.currentValueType
      this._searchItem.value = this.currentValue
      this._searchItem.valueList = []
    }
  }

  changeComponentType() {
    if (this.searchKey === BARCODE_SEARCH_KEYS.customField) {
      this._searchItem.valueType = this.currentValueType
      this._searchItem.value = this.currentValue
    }
  }

  loadTP(fromIdx: string) {
    if (isEmpty(fromIdx)) return [] as ITrackPointKeyVal[]

    const keys = Object.keys(
      (this.trackingPointDatasArr.find((tpData) => tpData.key === fromIdx) as ITrackPointKeyVal)?.value
        ?.trackPointForms ?? {}
    )

    return this.trackingPointDatasArr.filter((tpData) => keys.includes(tpData.key))
  }
}
</script>
<style lang="scss" scoped>
.search-value-field :deep() {
  .el-date-editor.el-input {
    width: 100%;
    height: 36px;
  }
}
</style>
