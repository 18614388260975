<template>
  <SvgBase iconName="RegexIcon" width="15" height="14">
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.012 2h.976v3.113l2.56-1.557.486.885L11.47 6l2.564 1.559-.485.885-2.561-1.557V10h-.976V6.887l-2.56 1.557-.486-.885L9.53 6 6.966 4.441l.485-.885 2.561 1.557V2zM2 10h4v4H2v-4z"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'
@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class RegexIcon extends Vue {}
</script>
