<template v-loading.fullscreen="loading">
  <div class="w-1/4">
    <el-select
      v-model="_searchItem.subKey"
      :class="'w-full'"
      size="medium"
      :placeholder="$t('selectOne')"
      @change="changeSubKey"
    >
      <el-option v-for="(item, key) in addressKeys" :key="key" :label="$t(item)" :value="key">
        {{ $t(item) }}
      </el-option>
    </el-select>
  </div>
  <div class="w-1/4">
    <template v-if="isCountry">
      <div class="w-full" v-loading.fullscreen="loading">
        <el-select
          v-model="_searchItem.value"
          filterable
          :class="'w-full'"
          size="medium"
          :placeholder="$t('selectOne')"
        >
          <el-option-group
            v-for="(group, groupKey) in countries"
            :key="groupKey"
            :label="$t(`countryGroup.${groupKey}`)"
          >
            <el-option v-for="country in group" :key="country" :value="country" :label="$t(`countryList.${country}`)">
              {{ $t(`countryList.${country}`) }}
            </el-option>
          </el-option-group>
        </el-select>
      </div>
    </template>
    <el-input :class="'w-full'" size="medium" v-model="_searchItem.value" v-else>
      <template #suffix>
        <ExactValueButton :searchItem="_searchItem" />
      </template>
    </el-input>
  </div>
</template>

<script lang="ts">
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import { FETCH_COUNTRIES } from '@/store/actions'
import { BARCODE_SERCH_ADDRESS_KEYS, POPULAR_COUNTRIES } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import ExactValueButton from '../common/ExactValueButton.vue'

@Options({
  components: {
    ExactValueButton,
  },
  name: 'SearchItemAddress',
})
export default class SearchItemAddress extends Mixins(SearchItemField) {
  isCountry = false
  loading = false

  async created() {
    if (!this.$store.state.profile?.isLoadCountry) {
      this.loading = true
      await this.$store.dispatch(FETCH_COUNTRIES).finally(() => (this.loading = false))
    }
    this.oldData = cloneDeep(this._searchItem)
    this.isCountry = this._searchItem.subKey === BARCODE_SERCH_ADDRESS_KEYS.country
  }

  changeSubKey(newValue: string) {
    this.isCountry = newValue === BARCODE_SERCH_ADDRESS_KEYS.country
    this._searchItem.value = ''
    this._searchItem.isExactValue = false
  }

  get addressKeys() {
    return BARCODE_SERCH_ADDRESS_KEYS
  }

  get countries() {
    return {
      popularCountries: { ...POPULAR_COUNTRIES },
      allCountries: { ...POPULAR_COUNTRIES },
    }
  }
}
</script>
