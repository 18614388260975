<template>
  <div class="w-2/4">
    <Number v-model="_searchItem.value" :controls="true" />
  </div>
</template>

<script lang="ts">
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import cloneDeep from 'lodash/cloneDeep'
import { IBarcodeSearchFormItem } from 'smartbarcode-web-core/src/utils/types'
import { Options } from 'vue-class-component'
import { Mixins, PropSync } from 'vue-property-decorator'
import Number from '@/components/barcodeSearch/customField/Number.vue'

@Options({
  components: { Number },
  name: 'SearchItemTextbox',
})
export default class SearchItemTextbox extends Mixins(SearchItemField) {
  @PropSync('searchItem', { type: Object }) _searchItem!: IBarcodeSearchFormItem

  created() {
    this.oldData = cloneDeep(this._searchItem)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';
.el-input__suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
