
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import { FETCH_COUNTRIES } from '@/store/actions'
import { BARCODE_SERCH_ADDRESS_KEYS, POPULAR_COUNTRIES } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import ExactValueButton from '../common/ExactValueButton.vue'

@Options({
  components: {
    ExactValueButton,
  },
  name: 'SearchItemAddress',
})
export default class SearchItemAddress extends Mixins(SearchItemField) {
  isCountry = false
  loading = false

  async created() {
    if (!this.$store.state.profile?.isLoadCountry) {
      this.loading = true
      await this.$store.dispatch(FETCH_COUNTRIES).finally(() => (this.loading = false))
    }
    this.oldData = cloneDeep(this._searchItem)
    this.isCountry = this._searchItem.subKey === BARCODE_SERCH_ADDRESS_KEYS.country
  }

  changeSubKey(newValue: string) {
    this.isCountry = newValue === BARCODE_SERCH_ADDRESS_KEYS.country
    this._searchItem.value = ''
    this._searchItem.isExactValue = false
  }

  get addressKeys() {
    return BARCODE_SERCH_ADDRESS_KEYS
  }

  get countries() {
    return {
      popularCountries: { ...POPULAR_COUNTRIES },
      allCountries: { ...POPULAR_COUNTRIES },
    }
  }
}
