<template>
  <el-tooltip
    class="box-item"
    effect="dark"
    :content="!_searchItem.isExactValue ? $t('disable_regex_search') : $t('enable_regex_search')"
    placement="top-start"
  >
    <el-check-tag :checked="!_searchItem.isExactValue" type="primary" @change="onChangeIsExactValue(_searchItem)">
      <RegexIcon />
    </el-check-tag>
  </el-tooltip>
</template>

<script lang="ts">
import { IBarcodeSearchFormItem } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import RegexIcon from '../svg/RegexIcon.vue'

@Options({
  components: {
    RegexIcon,
  },
  emits: ['update:isExactValue'],
  name: 'ExactValueButton',
})
export default class ExactValueButton extends Vue {
  @PropSync('searchItem', { type: Object }) _searchItem!: IBarcodeSearchFormItem

  onChangeIsExactValue = (value: IBarcodeSearchFormItem) => {
    value.isExactValue = !value.isExactValue
  }
}
</script>
