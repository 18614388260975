
import { IBarcodeSearchFormItem } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import RegexIcon from '../svg/RegexIcon.vue'

@Options({
  components: {
    RegexIcon,
  },
  emits: ['update:isExactValue'],
  name: 'ExactValueButton',
})
export default class ExactValueButton extends Vue {
  @PropSync('searchItem', { type: Object }) _searchItem!: IBarcodeSearchFormItem

  onChangeIsExactValue = (value: IBarcodeSearchFormItem) => {
    value.isExactValue = !value.isExactValue
  }
}
